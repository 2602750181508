import { ViewerPageProvider } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useViewerContext } from 'pages/viewer/ui/ViewerContext'
import { ReactNode, useEffect } from 'react'
import { EventBusProvider } from 'shared/lib/EventBus'
import ISlide from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import useDeepCompareEffect from 'use-deep-compare-effect'

type Props = {
  /** Дочерние элементы компонента */
  children: ReactNode
  /** Массив слайдов */
  slides?: ISlide[]
  filesList?: string[]
  /** Массив ID слайдов */
  slidesIds?: number[]
  /** Флаг загрузки из слайдов */
  isLoading?: boolean
  /** Массив объектов слайдов, сгруппированных по блокам */
  slideGrouped?: ISlideGrouped[]
  /** Флаг режима просмотра дефектов */
  isDefectModeViewer?: boolean
}

const CommonViewerRouteLayout = ({
  children,
  filesList,
  isDefectModeViewer,
  isLoading,
  slideGrouped,
  slides,
  slidesIds,
}: Props) => {
  const { setDefectModeViewer, setFilesList, setLoading, setSlideGrouped, setSlides, setSlidesIds } = useViewerContext()

  useEffect(() => {
    setLoading(!!isLoading)
    setDefectModeViewer(!!isDefectModeViewer)
  }, [isLoading, isDefectModeViewer])

  useDeepCompareEffect(() => {
    if (slides?.length) {
      setSlides(slides)
    }
    if (slidesIds?.length) {
      setSlidesIds(slidesIds)
    }
    if (slideGrouped?.length) {
      setSlideGrouped(slideGrouped)
    }
    if (filesList?.length) {
      setFilesList(filesList)
    }
  }, [slideGrouped, filesList, slides, slidesIds])

  return (
    <ViewerPageProvider>
      <EventBusProvider>{children}</EventBusProvider>
    </ViewerPageProvider>
  )
}

export default CommonViewerRouteLayout
