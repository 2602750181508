import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import CommonViewerRouteLayout from 'pages/viewer/ui/common/CommonViewerRouteLayout'
import ViewerContextProvider from 'pages/viewer/ui/ViewerContext'

import DefectsViewerContainer from './DefectsViewerContainer'

export const DefectsViewerRoute = () => {
  const { slides, slidesIds: defectsSlidesIds } = useSlidesDefects()

  return (
    <ViewerContextProvider>
      <CommonViewerRouteLayout isDefectModeViewer={true} slidesIds={defectsSlidesIds} slides={slides}>
        <DefectsViewerContainer />
      </CommonViewerRouteLayout>
    </ViewerContextProvider>
  )
}
