import { useSlideGrouped } from 'features/thumbnails/hooks'
import { selectUrlCaseId } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import CommonViewerRouteLayout from 'pages/viewer/ui/common/CommonViewerRouteLayout'
import ViewerContextProvider from 'pages/viewer/ui/ViewerContext'
import { useSelector } from 'react-redux'

import ViewerContainer from './ViewerContainer'

export const ViewerRoute = () => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId } = useViewerIdSlideState(viewerId)
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const { filesList, isLoadingCaseGrouped, slideGrouped, slides, slidesIds: slideIds } = useSlideGrouped(urlCaseId)

  return (
    <ViewerContextProvider>
      <CommonViewerRouteLayout
        slideGrouped={slideGrouped}
        filesList={filesList}
        slidesIds={slideIds}
        isLoading={isLoadingCaseGrouped}
        slides={slides}
      >
        <ViewerContainer />
      </CommonViewerRouteLayout>
    </ViewerContextProvider>
  )
}
